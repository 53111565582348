import React from "react";
import BackgroundChecks from "../../../components/Landers/BackgroundChecks";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import CustomizedSolutions from "../../../components/Landers/CustomizedSolutions";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import AllChecks from "../../../components/Landers/AllCheck";
import DataInfo from "../../../components/Data";

const solutions1 = [
  {
    h5: "Robust yet Reliable Verifications",
    p:
      "Choose the set of checks based on your requirements and run them with SpringVerify. Mitigate the risk of costly wrong hires by running checks thoroughly.",
  },
  {
    h5: "Fast and Reliable Results",
    p:
      "Timing is everything when it comes to closing candidates. Get fast, accurate, and reliable results so you can fill in-demand roles faster.",
  },
  {
    h5: "Stay Compliant with Rules and Regulations",
    p:
      "Customize your screening program to meet regulatory and licensing regulations, including ISO, PCI, and more.",
  },
];

const customized = [
  {
    h6: "Powered by AI and Blockchain",
    h4: "Background Checks \nbuilt for the Modern Era",
    p:
      "Get quick results on criminal searches, driving history, and identity validation to keep your hiring running smoothly. Our continuous monitoring system notifies you in case of any flags or adverse actions in real-time.",
  },
  {
    h6: "Candidate Portal",
    h4: "Frictionless Candidate \nExperience",
    p:
      "Candidates can submit the information quickly and track the status in real-time. They can also connect with our live support team in case of any queries.",
  },
  {
    h6: "Custom Solutions",
    h4: "Flexible Solutions based \non the Roles",
    p:
      "We understand your unique needs, and we’ve built a customized background screening program in place. Choose from the range of checks you want to run and ensure you hire the right candidate with confidence.",
  },
];

const testimonials = [
  {
    name: "Rupali M",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal.
    It provides real-time updates making our HR job easier.
    The entire process is so seamless saving us a lot of time, effort and resources in the process. `,
  },
  {
    name: "Manvi K ",
    company: "Inshorts",
    p: `One thing that really stands-out is their GREAT customer service.
    The Dedicated Account Manager responds in a very timely manner to any question at the drop of a
    hat and you really can't ask for more than that!`,
  },
  {
    name: "Nandita N",
    company: "Umlaut Pvt. Ltd.",
    p: `SpringVerify is a true all-rounder when it comes to background verifications.
    Most services I've used in the past have always had compromises. But SpringVerify has been exceptional and impressive -
    right from the variety of checks it offers, its reliablity to 24x7 customer support. `,
  },
];

export default () => (
  <div className={`${styles.technology} ${styles.m0}`}>
    <SEO
      title="Background Checks for Technology Companies | SpringVerify India"
      desc="As the competition to hire top talent is getting fierce, don’t let background verifications become the bottleneck in hiring the best candidate for your Tech company."
      url="https://in.springverify.com/industry/technology/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/solution/SV-IN+(IT+%26+SaaS).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Tech-First Background Verifications"
        p="As the competition to hire top talent is getting fierce, don’t let background verifications become the bottleneck in hiring. SpringVerify helps you screen the right candidates in a highly competitive talent pool."
        img="https://assets-sv-in.gumlet.io/LP/Tech/banner.png"
      />
    </div>
    <Brands
      list={["wazirx", "inshorts", "rently", "airmeet", "simpl", "udaan"]}
    />
    <AllChecks data={DataInfo.TechAllCheck} />
    <CustomizedSolutions
      h2="We understand the Tech Industry"
      solutions={solutions1}
      path="Tech"
    />
    <section className={`${styles.customized} ${styles.plain}`}>
      <h2>
        Customized Solutions
        <br />
        Built for Tech Companies
      </h2>
      <div className={styles.cards}>
        {customized.map(({ h4, h6, p }, i) => (
          <div className={styles.card}>
            <div className={styles.text}>
              <img
                data-src={`https://assets-sv-in.gumlet.io/LP/Tech/Customized/${
                  i + 1
                }.svg`}
                alt=""
              />
              <h6>{h6}</h6>
              <h4>{h4}</h4>
              <p>{p}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      className={styles.TechGetStarted}
      img="Tech/get-started.svg"
      h3="Make the switch for a better screening experience."
      p="You’re just one conversation away from knowing  why top IT and SaaS companies love SpringVerify!"
    />
    <Footer />
  </div>
);
